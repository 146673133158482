<template>
  <b-row
    align-v="start"
    class="mb-3"
  >
    <b-col
      md="12"
      xl="9"
    >
      <h4 class="mb-2">{{ $t('customer') }}
        <template
          v-if="customer.telegramUsername"
        >
          /
          <span class="primary-color">
            {{ customer.telegramUsername }}
          </span>
        </template>
        <template v-if="customer.telegramId">
          / <span class="primary-color"> {{ customer.telegramId }}</span>
        </template>
      </h4>

      <b-row>
        <template v-for="item in infoRow">
          <b-col
            v-if="item.value"
            :key="item.label"
            mb="6"
            xl="3"
          >
            <div class="d-flex flex-row align-items-center text-nowrap">
              {{ $t(item.label) }}&nbsp;
              <KycStatus
                v-if="item.type === 'status'"
                :status="item.value"
              />
              <KycLevel
                v-else-if="item.type === 'level'"
                :level="item.value"
              />
              <b
                v-else
                class="text-nowrap"
              >{{ item.value }}</b>
            </div>
          </b-col>
        </template>
      </b-row>
    </b-col>
    <b-col
      class="justify-content-xl-end d-flex mt-1 mt-xl-0 g-8"
      md="12"
      xl="3"
    >
      <b-button
        v-if="isShowViewButton"
        :to="`/kyc/${mainData.id}/detail`"
        class="text-left customer-button"
        variant="outline-primary"
      >
        {{ $t('kyc.viewFullProfile') }}
      </b-button>

      <b-button
        v-if="isShowDownloadInfoZipButton"
        :disabled="isZipLoading"
        class="text-left customer-button"
        variant="outline-primary"
        @click="downloadZip"
      >
        {{ $t('kyc.downloadInfo') }}
        <b-spinner
          v-if="isZipLoading"
          class="ml-1"
          small
        />
      </b-button>
      <b-button
        class="text-left"
        target="_blank"
        variant="primary"
        @click="onClickChat"
      >
        {{ $t("Chat") }}
      </b-button>
      <b-button
        v-if="isShowDownloadUserPdfButton"
        :disabled="isPdfLoading"
        class="d-flex flex-row align-items-center customer-button"
        variant="outline-primary"
        @click="downloadPdf"
      >
        {{ $t('downloadPDF') }}
        <b-spinner
          v-if="isPdfLoading"
          class="ml-1"
          small
        />
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import {
  BButton, BCol, BRow, BSpinner,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { formattedDate } from '@/tools/util'
import KycStatus from '@/views/apps/kyc/components/status.vue'
import KycLevel from '@/views/apps/kyc/components/level.vue'
import { kycData } from '@/views/apps/kyc/config/kycTypes'
import checkErrorsAlert from '@/mixins/checkErrorsAlert'
import waitRequest from '@/mixins/waitRequest'

export default {
  name: 'KycCustomer',
  components: {
    KycStatus,
    KycLevel,
    BButton,
    BRow,
    BCol,
    BSpinner,
  },
  mixins: [checkErrorsAlert, waitRequest],
  props: {
    mainData: {
      type: Object,
      default: () => ({}),
    },
    isShowViewButton: {
      type: Boolean,
      default: true,
    },
    isShowDownloadUserPdfButton: {
      type: Boolean,
      default: false,
    },
    isShowDownloadInfoZipButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isZipLoading: false,
      isPdfLoading: false,
      formattedDate,
    }
  },
  computed: {
    infoRow() {
      const submittedAt = this.mainData.submittedAt ? this.formattedDate(this.mainData.submittedAt, kycData) : ''

      return [
        {
          label: this.$t('status'),
          value: this.mainData.status,
          type: 'status',
        },
        {
          label: this.$t('kyc.kycLevel'),
          value: this.mainData.level,
          type: 'level',
        },
        {
          label: this.$t('kyc.applicationID'),
          value: this.mainData.id,
          type: 'text',
        },
        {
          label: this.$t('kyc.submittedAt'),
          value: submittedAt,
          type: 'text',
        },
      ]
    },
    customer() {
      return this.mainData.user
    },
  },
  methods: {
    ...mapActions({
      generatePdf: 'kyc/generatePdf',
      generateZip: 'kyc/generateZip',
    }),
    downloadZip() {
      if (this.isZipLoading) return
      this.isZipLoading = true
      this.waitRequest(async () => {
        await this.generateZip({ id: this.mainData.id })
          .then(({ data }) => {
            window.open(data.link, '_blank')
          })
          .catch(this.checkErrorsAlert)
          .finally(() => {
            this.isZipLoading = false
          })
      })
    },
    downloadPdf() {
      if (this.isPdfLoading) {
        return
      }
      this.isPdfLoading = true
      this.waitRequest(async () => {
        await this.generatePdf({ id: this.mainData.id }).then(({ data }) => {
          window.open(data.link, '_blank')
        }).catch(this.checkErrorsAlert).finally(() => {
          this.isPdfLoading = false
        })
      })

      // /kyc/generate-pdf
    },
    onClickChat() {
      const url = `/kyc/${this.mainData.id}/chat`
      window.open(url, '_blank')
      // this.$router.push(url)
    },
  },
}
</script>
<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.primary-color {
  color: $primary;
}

.g-8 {
  gap: 8px;
}

.customer-button {
  min-width: fit-content
}
</style>
